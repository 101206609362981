import { Injectable } from '@angular/core';

/**
 * Provides a reference to the native document object and makes it mockable for tests
 * Naming and structure inspired by native angular methods (elementRef.nativeElement etc.)
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentRef {
  private readonly document: Document;

  /**
   * Returns the native window object if it exists
   *
   * @returns
   */
  get nativeDocument(): Document {
    return this.document;
  }

  constructor() {
    this.document = <Document>document;
  }
}
